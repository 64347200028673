import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './global/Element'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import $ from 'jquery'
import 'bootstrap'
import BootstrapVue from 'bootstrap-vue'
import axios from 'axios'
import store from './vuex/store'
import './assets/font/iconfont.js'
import './assets/font/icon.css'
import request from './api/axios.js'
import api from './api/api.js'
import url from './api/config.js'
import utils from './util/util.js'
import i18n from './i18n'
import waterfall from 'vue-waterfall2'

Vue.prototype.$axios = axios
Vue.prototype.$ = $;
Vue.prototype.$request = request
Vue.prototype.$api = api
Vue.prototype.$url = url
Vue.prototype.$utils = utils
Vue.use(waterfall)
Vue.use(BootstrapVue)
Vue.config.productionTip = false


router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  render: h => h(App),
  router,
  i18n,
  store
}).$mount('#app')
