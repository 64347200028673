import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(Element)
import {
	Pagination,
	Select,
	OptionGroup,
	Option,
	Slider,
	Loading,
	ColorPicker,
	MessageBox,
	Message,
    Cascader,
    Checkbox,
    Button,
	Radio
} from 'element-ui'

Vue.use(Pagination)
Vue.use(Cascader);
Vue.use(Select)
Vue.use(OptionGroup)
Vue.use(Option)
Vue.use(Checkbox)
Vue.use(Radio)
Vue.use(Button)
Vue.use(Slider)
Vue.use(ColorPicker)
Vue.use(Loading.directive)
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$message = Message
Vue.prototype.$loading = Loading.service
