import i18n from '../i18n'
import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)


let routes = [{
		path: '/',
		name: 'home',
		component: () => import("@/view/index/index"),
		meta: {
			title: i18n.t("homeTitle")
		}
	},
	{
		path: '/projectDetail',
		name: 'projectDetail',
		component: () => import("@/view/projectDetail/projectDetail"),
		meta: {
			title: '楼盘方案详情'
		}
	},
	{
		path: '/buildingPlan',
		name: 'buildingPlan',
		component: () => import("@/view/buildingPlan/buildingPlan"),
		meta: {
			title: '楼盘方案'
		},
	},
	{
		path: '/productList',
		name: 'productList',
		component: () => import("@/view/index/productList"),
		meta: {
			title: '商品列表'
		}
	},
	{
		path: '/tool',
		name: 'tool',
		component: () => import("@/view/index/tool"),
		meta: {
			title: '美家搭配'
		}
	},
	{
		path: '/threeDimensional',
		name: 'threeDimensional',
		component: () => import("@/view/index/threeDimensional"),
		meta: {
			title: '720°VR设计'
		}
	},
	{
		path: '/twoDimensionalProgramme',
		name: 'twoDimensionalProgramme',
		component: () => import("@/view/twoDimensionalProgramme/twoDimensionalProgramme"),
		meta: {
			title: '二维方案'
		}
	},
	{
		path: '/details',
		name: 'details',
		component: () => import("@/view/details/details"),
		meta: {
			title: '商品详情'
		}
	},
	{
		path: '/packageDetails',
		name: 'packageDetails',
		component: () => import("@/view/packageDetails/packageDetails"),
		meta: {
			title: '套餐详情'
		}
	},
	{
		path: '/packageList',
		name: 'packageList',
		component: () => import("@/view/packageList/packageList"),
		meta: {
			title: '套餐列表'
		}
	}  ,
   // 登录
   {
    path: '/login',
    name: 'login',
    component: () => import("@/view/login/login"),
    meta: {
      title: '登录'
    }
  },
   // 注册
   {
    path: '/registered',
    name: 'registered',
    component: () => import("@/view/registered/registered"),
    meta: {
      title: '注册'
    }
  },
  // 忘记密码
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: () => import("@/view/forgotPassword/forgotPassword"),
    meta: {
      title: '忘记密码'
    }
  },
  {
    path: '/usercenter/accountSecurity',
    name: 'accountSecurity',
    component: () => import("@/view/userCenter/accountSecurity/accountSecurity"),
    meta: {
      title: '账户安全'
    }
  },
    // 个人资料
  {
    path: "/usercenter/userinfo",
    name: "userinfo",
    component: () => import("@/view/userCenter/userInfo/userInfo"),
    meta: {
      title:'个人资料'
    }
  }, 
   // 收货地址
  {
    path: "/usercenter/receivingAddress",
    name: "receivingAddress",
    component: () => import("@/view/userCenter/receivingAddress/receivingAddress"),
    meta: {
      title:'收货地址'
    }
  },
   // 订单管理
  {
    path: "/usercenter/orderManagement",
    name: "orderManagement",
    component: () => import("@/view/userCenter/orderManagement/orderManagement"),
    meta: {
      title: '订单管理'
    }
  },
    // 购物车
  {
    path: "/usercenter/shopCart",
    name: "shopCart",
    component: () => import("@/view/userCenter/shopCart/shopCart"),
    meta: {
      title: '购物车'
    }
  },
   // 我的收藏
  {
    path: '/usercenter/collectionRecord',
    name: 'collectionRecord',
    component: () => import('@/view/userCenter/collectionRecord/collectionRecord'),
    meta: {
      title: '我的收藏'
    }
  },
   // 商品订单选择支付方式
   {
    path: "/pay/orderPaymentMethod",
    name: "orderPaymentMethod",
    component: () => import("@/view/pay/orderPaymentMethod/orderPaymentMethod"),
    meta: {
      title: i18n.t("orderPaymentMethodTitle")
    }
  },
  // 商品订单支付
  {
    path: "/pay/orderPay",
    name: "orderPay",
    component: () => import("@/view/pay/orderPay/orderPay"),
    meta: {
      title: i18n.t("orderPayTitle")
    }
  },
   // 订单结算
   {
    path: '/orderSettlement',
    name: 'orderSettlement',
    component: () => import('@/view/mall/orderSettlement/orderSettlement'),
    meta: {
      title: i18n.t('orderSettlementTitle')
    }
  },
  // 订单结算
  {
    path: '/helpCenter',
    name: 'helpCenter',
    component: () => import('@/view/mall/helpCenter/helpCenter'),
    meta: {
      title: i18n.t('helpCenter')
    }
  },
   // 帮助中心
   {
    path: "/usercenter/personalCenter",
    name: "personalCenter",
    component: () => import("@/view/userCenter/personalCenter/personalCenter"),
    meta: {
      title:'帮助中心'
    }
  },
  // 订单详情
  {
   path: "/usercenter/orderDetail",
   name: "orderDetail",
   component: () => import("@/view/userCenter/orderDetail/orderDetail"),
   meta: {
     title:'订单详情'
   }
 },
 // 品牌详情
 {
   path: '/brandDetails',
   name: 'brandDetails',
   component: () => import("@/view/mall/brandDetails/brandDetails"),
   meta: {
     title: '品牌详情'
   }
 }
  // ,
  // // 个人资料
  // {
  //   path: "/usercenter/userinfo",
  //   name: "userinfo",
  //   component: () => import("@/view/userCenter/userInfo/userInfo"),
  //   meta: {
  //     title:'个人资料'
  //   }
  // },
 
 
 // 子账号管理
  // {
  //   path: '/usercenter/subAccountManagement',
  //   name: 'subAccountManagement',
  //   component: () => import("@/view/userCenter/subAccountManagement/subAccountManagement"),
  //   meta: {
  //     title: ' 子账号管理'
  //   }
  // },
  // // 查询用户套餐资源
  // {
  //   path: '/usercenter/packageResources',
  //   name: 'packageResources',
  //   component: () => import("@/view/userCenter/packageResources/packageResources"),
  //   meta: {
  //     title: '查询用户套餐资源'
  //   }
  // },
  // // 资产管理
  // {
  //   path: '/usercenter/assetManagement',
  //   name: 'assetManagement',
  //   component: () => import("@/view/userCenter/assetManagement/assetManagement"),
  //   meta: {
  //     title:' 资产管理'
  //   }
  // },
  
  // {
  //   path: "/test",
  //   name: "test",
  //   component: () => import("@/view/userCenter/test")
  // },
  // {
  //   path: "/test1",
  //   name: "test1",
  //   component: () => import("@/view/userCenter/test1")
  // }, // 商品管理
  // {
  //   path: '/usercenter/commodityManagement',
  //   name: 'commodityManagement',
  //   component: () => import("@/view/userCenter/commodityManagement/commodityManagement"),
  //   meta: {
  //     title: '商品管理'
  //   }
  // },
  // // 签到
  // {
  //   path: '/usercenter/signIn',
  //   name: 'signIn',
  //   component: () => import("@/view/userCenter/signIn/signIn"),
  //   meta: {
  //     title: '签到'
  //   }
  // },
  // // 账户安全
 
 
  // // 作品中心
  // {
  //   path: '/usercenter/worksCenter',
  //   name: 'worksCenter',
  //   component: () => import('@/view/userCenter/worksCenter/worksCenter'),
  //   meta: {
  //     title: '作品中心'
  //   }
  // },
  // // 下载记录 
  // {
  //   path: '/usercenter/downloadRecord',
  //   name: 'downloadRecord',
  //   component: () => import('@/view/userCenter/downloadRecord/downloadRecord'),
  //   meta: {
  //     title: '下载记录 '
  //   }
  // },
  // // 浏览记录
  // {
  //   path: '/usercenter/browseRecords',
  //   name: 'browseRecords',
  //   component: () => import('@/view/userCenter/browseRecords/browseRecords'),
  //   meta: {
  //     title:'浏览记录'
  //   }
  // }
]







const router = new Router({
	// mode: 'history',
	routes
});
// router.beforeEach((to, from, next) => {
// console.log('router',to,from)
// if (to.path === '/Login') {
// 	localStorage.removeItem('token');
// 	next()
// }
// let user = localStorage.getItem('token');
// if (!user) {
// 	if (to.path == '/') {
// 		if (to.query.token) {
// 			localStorage.setItem('token',to.query.token)
// 			localStorage.setItem('phone',to.query.phone)
// 			localStorage.setItem('role_id',to.query.role_id)
// 			next()
// 		} else {
// 			// next({path: 'Login'})
// 		}
// 	} else {
// 		// next({path: 'Login'})
// 	}
// } else {
// next()
// }
// });
export default router
