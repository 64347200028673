import axios from 'axios'
import qs from 'qs'
// import Vue from "vue";
// import store from "../vuex/store.js";
// import router from "../router";
import dump from '../util/dump.js'
import urlConfig from './config.js'
import epeidaUrlConfig from './epeidaUrlConfig.js'

const request = {}
const headers = {
	"Content-Type": "application/x-www-form-urlencoded"
}

axios.interceptors.response.use(
	function(response) {
		let res = response.data;
		if (res.code == 403) {
			// Vue.prototype.$alert('账号在其他地方登陆，请重新登录？', {
			// 	confirmButtonText: '确定',
			// 	callback: action => {
			// 		console.log("登录")
			// 	}
			// })
		}
		return res;
	},
	function(error) {
		return Promise.reject(error);
	}
);

request.http = (url, method, requestData) => {
	let token = localStorage.getItem('token');
	if(token){
		requestData.token = token;
	}
	let params = {
		url: urlConfig + url,
		method: method,
		headers: headers
	};
	if (method == 'GET' || method == 'get') {
		// console.log('============>')
		params.params = requestData;
	} else {
		params.data = qs.stringify(requestData);
    // console.log(params)
	}
	// console.log(params);
	return new Promise((resolve, reject) => {
    // console.log(params)
		axios(params).then((res) => {
			if (res.code == 500 && res.msg == '请重新登录!') {
				localStorage.removeItem("token");
				dump.checkLogin();
				return false;
			}
			if (res.status == 103) {
				localStorage.removeItem("token");
				dump.checkLogin();
				return false;
			}
			resolve(res);
		}).catch((err) => {
			reject(err);
		})
	})
};
// request.http = (url,method,requestData) => {
// 	let token=localStorage.getItem("token"); 
//   console.log(token)
// 	requestData.token=token;
// 	let params={
// 		url: urlConfig+url,
// 		method: method,
// 		headers: headers
// 	};
// 	if(method =='GET' || method == 'get'){
// 		console.log('============>')
// 		params.params=requestData;
// 	}else{
// 		params.data=qs.stringify(requestData);
// 	}
// 	console.log(params);
// 	return new Promise((resolve, reject) => {
// 		axios(params).then((res) => {
// 			resolve(res);
// 		}).catch((err) => {
// 			reject(err);
// 		})
// 	})
// };

request.epeidaHttp = (url,method,requestData) => {
let token=localStorage.getItem("token");
	requestData.token=token;
	let params={
		url: epeidaUrlConfig+url,
		method: method,
		headers: headers
	};
	if(method =='GET' || method == 'get'){
		params.params=requestData;
	}else{
		
		params.data=qs.stringify(requestData);
	
	}

	return new Promise((resolve, reject) => {
		axios(params).then((res) => {
			resolve(res);
		}).catch((err) => {
			reject(err);
		})
	})
};


export default request;
