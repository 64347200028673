import request from "./axios.js";



const api = {}


api.banners  = params => request.http('/appbanner/getbanners.do', 'GET', params,1)
//获取所有设计师
api.designers  = params => request.epeidaHttp('/html/access/v1/getAllMember', 'POST', params,1)
//获取所有二维方案
api.designMasterpieces  = params => request.epeidaHttp('/html/access/v2/design/getAllMasterpiece', 'POST', params,1)
//获取三维方案
api.design3D  = params => request.http('/appdesigner/get720.do', 'POST', params,1)
//楼盘方案
api.buildingList  = params => request.http('/mapp/building/list.do', 'GET', params,1)
//楼盘方案风格
api.getStyle  = params => request.http('/label/getStyle.do', 'GET', params,1)
//楼盘方案详情
api.buildingDetail  = params => request.http('/mapp/building/getInfo', 'GET', params,1)
//首页自主搭配
api.getVr  = params => request.http('/appdesigner/get720.do', 'POST', params,1)
//首页套餐列表
api.getTaocan  = params => request.http('/appsetmeal/taocanList.do', 'POST', params,1)
//首页风格列表获取
api.getStyleGoods  = params => request.http('/apphome/getHomeSuperiors.do', 'POST', params,1)
//首页装点未来
api.getFuture  = params => request.http('/appgoods/getgoods.do', 'POST', params,1)
//获取首页用户信息
api.getUserInfo  = params => request.http('/appuserinfo/getuserinfo.do', 'POST', params,1)
//获取品牌
api.brandList  = params => request.http('/appgoods/listBrand.do', 'POST', params,1)
//获取风格
api.styleList  = params => request.http('/label/getStyle.do', 'POST', params,1)
//获取所有标签
api.labelList  = params => request.http('/label/labelList.do', 'POST', params,1)
//获取商品
api.getGoodsList  = params => request.http('/appgoods/getgoods.do', 'POST', params,1)
//收藏 collection_type  1商品 2 品牌 3模型
api.collection   = params => request.http('/appcollection/collection', 'POST', params,1)
//获取三维方案
api.getDesign3DLIst  = params => request.http('/mapp/threeDimensional/list', 'GET', params,1)
// 获取个人信息 
api.getUserInfo = params => request.http("/appuserinfo/getuserinfo.do", "post", params)
// 获取热销商品 
api.hotSaleGoods = params => request.http("/appgoods/hotSaleGoods.do", "post", params)
// 登陆
api.login = params => request.http("/applogin/login.do", "post", params)
// 设置价格开关
api.checkboxSet = params => request.http("/appuserinfo/checkboxSet.do", "post", params)
// 二维方案详情
api.design2DDetail = params => request.epeidaHttp("/html/access/v1/getProjectInfo", 'post', params)
// 获取省市区
api.cityList = params => request.http("/appregion/nextlist.do", "post", params)
// 注册获取验证码
api.appRegister = params => request.http("/appsendmessage/sendSms/appRegister.do", "post", params)
// 注册获取随机数
api.getRandomNum = params => request.http("/appsendmessage/getRandomNum.do", "post", params)
// 立即注册
api.register = params => request.http("/appregiseter/register.do", "post", params)
// 重置密码
api.resetPsd = params => request.http("/applogin/resetPsd.do", "post", params)
// 获取重置密码验证码
api.appForgetPsd = params => request.http("/appsendmessage/sendSms/appForgetPsd.do", "post", params)
// 二维方案详情方案包含的单品
api.design2DItem = params => request.http("/appgoods/getgoodslistinfo", 'get', params) 
// 取消订单 
api.cancelOrder = params => request.http("/appmyorder/cancelOrder.do", "post", params)

// 获取用户所有地址 
api.allUserAddress = params => request.http("/appuseraddress/myAllUserAddress.do", "post", params)
// 获取用户地址 
api.getUserAddress = params => request.http("/appuseraddress/myAllUserAddress.do", "post", params)
// 保存用户地址
api.saveUserAddress = params => request.http("/appuseraddress/saveUserAddress.do", "post", params)
// 删除用户地址
api.delUserAddress = params => request.http("/appuseraddress/deleteUserAddress.do", "post", params)
// 二维方案收藏或取消收藏
api.design2DCollection = params => request.epeidaHttp("/html/access/v2/design/CollectOrNot", 'post', params)
// 二维方案再创作
api.reproduce = params => request.http("/appuserinfo/epeida.do", 'post', params)
//关注/取消关注设计师
api.followDesigner   = params => request.epeidaHttp('/html/access/v1/addOrRemoveFollow', 'POST', params,1)

//收藏或取消收藏二维方案
api.followSolution   = params => request.epeidaHttp('/html/access/v2/design/CollectOrNot', 'POST', params,1)

//校验密码
api.epeida   = params => request.http('/appuserinfo/epeida.do', 'POST', params,1)
// 获取商品信息
api.getGoodsInfo = params => request.http("/appgoods/getgoodsinfo.do", "post", params)
// 获取商品详情参数
api.getGoodsParams = params => request.http("/appgoods/goodsParameterDetails.do", "post", params)
// 获取商品参数信息
api.getParamsInfo = params => request.http("/appgoods/getratioprice.do", "post", params)
// 人气搭配商品类型
api.getPopularGoodsTypes = params => request.http("/appgoodstype/getpopulargoodsTypes.do", "post", params)
// 获取人气搭配商品
api.getPopularGoods = params => request.http("/appgoods/popularGoods.do", "post", params)
// 获取同类商品
api.getSimilarGoods = params => request.http("/appgoods/similarGoods.do", "post", params)
// 产品收藏 
api.collect = params => request.http("/appcollection/collection.do", "post", params)
// 获取品牌logo
api.getBrandLogo = params => request.http("/appgoods/getlogo.do", "post", params)
// 商品太阳码 
api.goodsSunCode = params => request.http("/mapp/share/onlyGoodsQr", "get", params)
// // 立即购买提交订单
// api.submitOrder = params => request.http("/apppay/submitEditOrder.do", "post", params)
// 加入购物车
api.addToCart = params => request.http("/appshopcart/saveShopCart.do", "post", params)
// 人气搭配加入购物车
api.popularGoodsAddToCart = params => request.http("/matchinginfo/addShopCart.do", "post", params)
// 商品评价
api.goodsEvaluation = params => request.http("/appdiscuss/listDiscuss.do", "post", params)
//套餐详情
api.setmealDetails = params => request.http('/appsetmeal/setmealDetails.do', 'POST', params)
//套餐详情商品
api.packDetails = params => request.http('/mapp/design/all', 'POST', params)
//套餐提交订单
api.setmealSettlement = params => request.http('/appsetmeal/setmealSettlement.do', 'POST', params)

//套餐列表
api.setmealList = params => request.http('/appsetmeal/setmeals.do', 'POST', params)

//套餐列表
api.mallSetmealList = params => request.http('/mapp/setmeal/getSetmeal.do', 'POST', params)

//价格按钮价格开关数据
api.checkboxPassword = params => request.http('/appuserinfo/checkboxPassword.do', 'POST', params)

//价格按钮价格开关修改
api.checkboxSet = params => request.http('/appuserinfo/checkboxSet.do', 'POST', params)
// 获取商品SKU信息
api.goodsSkuInfo = params => request.http("/appGoodsSku/goodsSkuInfo", "get", params)
// 订单支付
api.orderPay = params => request.http("/apppay/payOrder.do", "post", params)
// 支付宝支付
api.aliPay = params => request.http("/mapp/pay/pc/pay", "post", params)
// 获取商品SKU信息
api.goodsSkuInfo = params => request.http("/appGoodsSku/goodsSkuInfo", "get", params)
// 订单支付
api.orderPay = params => request.http("/apppay/payOrder.do", "post", params)
// 支付宝支付
api.aliPay = params => request.http("/mapp/pay/pc/pay", "post", params)
// 订单列表 
api.orders = params => request.http("/appmyorder/allorder.do", "post", params)
// 确认收货 
api.confirmCollectGoods = params => request.http("/appmyorder/confirmCollectgoods.do", "post", params)
// 取消订单 
api.cancelOrder = params => request.http("/appmyorder/cancelOrder.do", "post", params)
// 获取用户地址 
api.getUserAddress = params => request.http("/appuseraddress/myAllUserAddress.do", "post", params)
// 保存用户地址
api.saveUserAddress = params => request.http("/appuseraddress/saveUserAddress.do", "post", params)
// 删除用户地址
api.delUserAddress = params => request.http("/appuseraddress/deleteUserAddress.do", "post", params)
// 获取用户购物车商品列表 
api.shopCartList = params => request.http("/appshopcart/shopCarts.do", "post", params)
// 增加/减少购物车某个商品的数量 
api.goodsQuantityChange = params => request.http("/appshopcart/editshopCarts.do", "post", params)
// 删除购物车中的商品
api.delShopCartItem = params => request.http("/appshopcart/deleteshopCarts.do", "post", params)
// 删除购物车中所选中的多个商品 
api.delShopCartItems = params => request.http("/appshopcart/emptyshopCarts.do", "post", params)
// 购物车商品结算
api.settlement = params => request.http("/appsettlement/settlement.do", "post", params)
// 购物车导出清单
api.exportList = params => request.http("/matchinginfo/setShare.do", "post", params)
// 帮助中心类型
api.gettypes = params => request.http("/apphelpcenter/gettypes.do", "post", params)
// 帮助中心内容
api.getinfo = params => request.http("/apphelpcenter/getinfo.do", "post", params)

// 会员商品收藏列表 
api.goodsCollectionList = params => request.http("/appcollection/goodsCollectionList.do", "post", params)
// 会员品牌收藏列表 
api.bandCollectionList = params => request.http("/appcollection/bandCollectionList.do", "post", params)
// 会员模型收藏列表 
api.modelCollectionList = params => request.http("/appcollection/modelCollectionList", "post", params)
// 会员二维方案收藏列表 
api.twoDimensionCollect = params => request.epeidaHttp("/html/access/v2/design/getCollection", "post", params)
// 会员作品中心
api.myTwoDimension = params => request.epeidaHttp("/html/access/v1/getMyTwoDimension", "post", params)
// 个人中心用户换头 
api.changeAvatar = params => request.http("/mapp/plan/swapHeadUrl", "get", params)
// 获取主推商品 
api.mainProducts = params => request.http("/goods/goodsNow.do", "post", params)
// 人气搭配加入购物车
api.popularGoodsAddToCart = params => request.http("/matchinginfo/addShopCart.do", "post", params)
// 商品评价
api.goodsEvaluation = params => request.http("/appdiscuss/listDiscuss.do", "post", params)
// 订单支付
api.orderPay = params => request.http("/apppay/payOrder.do", "post", params)
// 支付宝支付
api.aliPay = params => request.http("/mapp/pay/pc/pay", "post", params)
// 立即购买提交订单
api.submitOrder = params => request.http("/apppay/submitOrder.do", "post", params)
// 检查商品订单是否已支付
api.checkOrderPay = params => request.http("/appcheck/check.do", "post", params)
// 获取产品风格 
api.goodsStyles = params => request.http("/appgoodstype/getstyles.do", "post", params)
// 商城热销排行类型
api.hotSellTypeLabel = params => request.http("/label/labelList.do", "post", params)
// 商城热销商品
api.hotSellGoods = params => request.http("/appgoods/goodsNewgoodslist.do", "post", params)
// 商城新品推荐 
api.newProduct = params => request.http("/appgoods/goodsNewgoodslist.do", "post", params)
// 获取商品
api.getGoodsList  = params => request.http('/appgoods/getgoods.do', 'POST', params,1)
// 商城活动专区 
api.discount = params => request.http("/appgoods/goodsDiscountlist.do", "post", params)
// 浏览记录 
api.browseRecords = params => request.http("/mapp/browse/getBrowse", "get", params)
// 获取用户所有地址 
api.allUserAddress = params => request.http("/appuseraddress/myAllUserAddress.do", "post", params)
// 获取结算商品信息 
api.submitOrderPage = params => request.http("/apppay/submitOrderPage.do", "post", params)
// 保存用户地址 
api.saveUserAddress = params => request.http("/appuseraddress/saveUserAddress.do", "post", params)
//用户提交订单 
api.submitEditOrder = params => request.http("/apppay/submitEditOrder.do", "post", params)
//订单详情 
api.orderDetails = params => request.http("/appmyorder/orderDetails.do", "post", params)
//删除订单 
api.deleteOrder = params => request.http("/appmyorder/deleteOrder.do", "post", params)
// 商城品牌信息
api.getbrandinfo = params => request.http("/appbrand/getbrandinfo.do", "post", params)
// 商城品牌详情数据
api.getbrandgoods = params => request.http("/appbrand/getbrandgoods.do", "post", params)
// 商城品牌收藏
api.brandCollection = params => request.http("/appcollection/collection.do", "post", params)

export default api;



