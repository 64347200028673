const dump = {}
import router from '../router'
import epeida_url_config from '../api/epeidaUrlConfig.js'
dump.link = (item) => {
  
	if (!item || !item.link || item.link == '' || item.link == "#") {
		return false;
	}
	if (item.type == 1) {
		let d = {
			path: item.link
		}
		if (item.params) {
			d.query = item.params;
		}
		router.push(d);
	} else if (item.type == 2) {
		// window.location.href=item.link;
		var ua = navigator.userAgent.toLowerCase();
		let s = ua.match(/version\/([\d.]+).*safari/) ? 1 : 0;
		//如果是safari浏览器
		if (s == 1) {
			window.location.href = item.link;
			return false;
		}
		if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(ua)) {
			//跳转移动端页面
			window.location.href = item.link;
			return false;
		}
		window.open(item.link);
	} else {
		window.location.href = item.link;
	}
}
dump.checkLogin = () => {
	let token = localStorage.getItem("token");
	if (!token) {
		let item = {
			type: 3,
			link: "/#/login"
		}
		dump.link(item);
		return false;
	}
	return true;
}
dump.toEPeida = (res) => {
	//跳转美家搭配
	if (res.status == 103) {
		localStorage.removeItem("token");
	} else {
		let link = epeida_url_config + '/html/v1/redirect/login?phone=' + sessionStorage.getItem(
			'phone') + '&token=' + localStorage.getItem("token") + '&role_id=' + localStorage.getItem(
			'role_id');
		let data = {
			type: 2,
			link: link
		}
		dump.link(data);
	}
}
export default dump;
